<template>
  <div class="space-basic">
    <b-card
      :img-src="picURI"
      img-height="250px"
    >
      <h2 class="">
        {{ title }}
      </h2>
      <div class="">
        {{ subTitle }}
      </div>
      <div class="text-right text-large text-primary mb-2">
        {{ price.toLocaleString() }} 円(税込)
      </div>
      <div class="text-right mb-3">
        <b-button
          variant="primary"
          class=""
          @click="apply"
        >
          <feather-icon
            icon="ShoppingCartIcon"
            class="mr-50"
          />
          <span>カートに入れる</span>
        </b-button>
      </div>
      <div class="mb-2 text-secondary">
        <small>※サンプル動画をご覧頂けます。</small>
      </div>
      <div
        v-for="(item, index) in lessonList"
        :key="index"
        class="mb-1"
      >
        <div class="d-flex justify-content-between">
          <div class="">
            {{ item.lessonName }}
          </div>
          <div class="text-rights">
            <b-button
              v-if="item.isSample && item.isSample.value === 1"
              variant="text-primary"
              size="sm"
              class="btn-wishlist"
              @click="showVideo(item.lessonURI)"
            >
              <feather-icon
                icon="PlayCircleIcon"
                class="mr-50"
              />
              再生
            </b-button>
            <b-button
              v-else
              variant="text-primary"
              size="sm"
              disabled
              class="btn-wishlist"
            >
              <feather-icon
                icon="PlayCircleIcon"
                class="mr-50"
              />
              再生
            </b-button>
          </div>
        </div>
      </div>
      <div class="text-right mt-3">
        <b-button
          variant="primary"
          class=""
        >
          <feather-icon
            icon="ShoppingCartIcon"
            class="mr-50"
          />
          <span>カートに入れる</span>
        </b-button>
      </div>
    </b-card>
    <b-modal
      v-model="isShowModal"
      size="lg"
      ok-only
      ok-title="閉じる"
      centered
      @ok="refetchEvents"
    >
      <iframe
        :src="movieURI"
        width="640"
        height="400"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        class="videoSpace"
      />
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BButton,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import useLesson from '@/components/js/compositionAPI/lesson/useLesson'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default {
  components: {
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const title = ref('')
    const subTitle = ref('')
    const price = ref(0)
    const picURI = ref('')
    const movieURI = ref('')
    const lessonList = ref([])
    const isShowModal = ref(false)
    const { route, router } = useRouter()
    const {
      categoryA,
      getCategoryListsScoped,
    } = useLesson()

    onMounted(async () => {
      const category = categoryA.find(v => v.id === Number(route.value.query.id))
      title.value = category.labelName
      subTitle.value = category.subTitle
      price.value = category.price
      picURI.value = category.picURI
      const ida = category.value
      const d = {
        categoryA: String(ida),
        categoryB: String(1),
      }
      lessonList.value = await getCategoryListsScoped(d)
    })

    const showVideo = item => {
      isShowModal.value = true
      const uri = item.split('/')
      movieURI.value = `https://player.vimeo.com/video/${uri[3]}?h=${uri[4]}`
    }
    const refetchEvents = () => {
      isShowModal.value = false
    }

    const apply = () => {
      store.dispatch('payment/pushItemToCartList', route.value.query.id)
      router.push({ name: 'cart' })
    }

    return {
      isShowModal,
      title,
      subTitle,
      price,
      picURI,
      movieURI,
      lessonList,
      showVideo,
      refetchEvents,
      apply,
    }
  },
}
</script>

<style scoped>
.videoSpace {
  width: 100%;
  margin: 0 auto 0 auto;
}
</style>
