<template>
  <DisplayLesson />
</template>

<script>
import DisplayLesson from '@/components/basic/lesson/DisplayLesson.vue'

export default {
  components: {
    DisplayLesson,
  },
}
</script>
